var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _App_instances, _App_getEnvironment, _App_getUser;
var Environment;
(function (Environment) {
    Environment["PRODUCTION"] = "PRODUCTION";
    Environment["STAGING"] = "STAGING";
    Environment["LOCAL"] = "LOCAL";
})(Environment || (Environment = {}));
var FormLayout;
(function (FormLayout) {
    FormLayout["VERTICAL"] = "VERTICAL";
    FormLayout["HORIZONTAL"] = "HORIZONTAL";
})(FormLayout || (FormLayout = {}));
var FormType;
(function (FormType) {
    FormType["SCRAP_CAR"] = "SCRAP_CAR";
    FormType["SALVAGE"] = "SALVAGE";
    FormType["SCRAP_METAL"] = "SCRAP_METAL";
    FormType["AUTO_PARTS"] = "AUTO_PARTS";
})(FormType || (FormType = {}));
const fields = {
    'waste': `
        <div class='form-field-wrapper waste-field-wrapper'>
            <div class="field-with-icon">
                <div class='field-icon-wrapper vrm-field-icon-wrapper'>
                    <img src='{waste_icon}' alt='' width='{waste_icon_width}px' height='{waste_icon_height}px' />
                </div>
                <div class='field-input-wrapper waste-field-input'>
                    <select class='field-input waste-field-input' name='waste'>
                        <option value="RESIDENTIAL">Residential Waste</option>
                        <option value="COMMERCIAL">Commercial Waste</option>
                    </select>
                </div>
            </div>
            <div class="field-error vrm-field-error"></div>
        </div>`,
    'vrm': `
        <div class='form-field-wrapper vrm-field-wrapper'>
            <div class="field-with-icon">
                <div class='field-icon-wrapper vrm-field-icon-wrapper'>
                    <img src='{vrm_icon}' alt='' width='{vrm_icon_width}px' height='{vrm_icon_height}px' />
                </div>
                <div class='field-input-wrapper vrm-field-input'>
                    <input class='field-input vrm-field-input' type='text' name='vrm' placeholder='Vehicle Reg' />
                </div>
            </div>
            <div class="field-error vrm-field-error"></div>
        </div>`,
    'postcode': `
        <div class='form-field-wrapper postcode-field-wrapper'>
            <div class="field-with-icon">
                <div class='field-icon-wrapper postcode-field-icon-wrapper'>
                    <img src='{postcode_icon}' alt='' width='{vrm_icon_width}px' height='{postcode_icon_height}px' />
                </div>
                <div class='field-input-wrapper postcode-field-input-wrapper'>
                    <input class='field-input postcode-field-input' type='text' name='postcode' placeholder='Postcode' />
                </div>
            </div>
            <div class="field-error postcode-field-error"></div>
        </div>`,
    'phone': `
        <div class='form-field-wrapper phone-field-wrapper'>
            <div class="field-with-icon">
                <div class='field-icon-wrapper phone-field-icon-wrapper'>
                    <img src='{phone_icon}' alt='' width='{phone_icon_width}px' height='{phone_icon_height}px' />
                </div>
                <div class='field-input-wrapper phone-field-input-wrapper'>
                    <input class='field-input phone-field-input' type='phone' name='phone' placeholder='Phone' />
                </div>
            </div>
            <div class="field-error phone-field-error"></div>
        </div>`,
    'email': `
        <div class='form-field-wrapper email-field-wrapper'>
            <div class="field-with-icon">
                <div class='field-icon-wrapper email-field-icon-wrapper'>
                    <img src='{email_icon}' alt='' width='{email_icon_width}px' height='{email_icon_height}px' />
                </div>
                <div class='field-input-wrapper email-field-input-wrapper'>
                    <input class='field-input email-field-input' type='email' name='email' placeholder='Email' />
                </div>
            </div>
            <div class="field-error email-field-error"></div>
        </div>`
};
const formFields = {
    [FormType.SCRAP_CAR]: {
        'vrm': fields.vrm,
        'postcode': fields.postcode,
        'phone': fields.phone,
        'email': fields.email,
    },
    [FormType.SALVAGE]: {
        'vrm': fields.vrm,
        'postcode': fields.postcode,
        'phone': fields.phone,
        'email': fields.email,
    },
    [FormType.SCRAP_METAL]: {
        'waste': fields.waste,
        'postcode': fields.postcode,
        'phone': fields.phone,
        'email': fields.email,
    },
    [FormType.AUTO_PARTS]: {
        'vrm': fields.vrm,
        'postcode': fields.postcode,
        'phone': fields.phone,
        'email': fields.email,
    }
};
import './assets/styles.scss';
export default class App {
    constructor() {
        var _a;
        _App_instances.add(this);
        this.forms = [];
        this.globalConfig = {
            environment: null,
            user: {
                ipAddress: null,
                userAgent: null,
            },
            submissionSourceIdentifier: ((_a = window.location) === null || _a === void 0 ? void 0 : _a.host) || 'IDENTIFIER_NOT_PROVIDED',
            submissionEndpoint: null,
            stageTwoEndpoint: null
        };
    }
    boot(config) {
        var _a;
        if (!config.submissionEndpoint || !config.stageTwoEndpoint) {
            console.log('You must specify a submissionEndpoint and stageTwoEndpoint as part of the form configuration');
            return false;
        }
        this.globalConfig.environment = config.environment || Environment.PRODUCTION;
        this.globalConfig.submissionSourceIdentifier = config.submissionSourceIdentifier || ((_a = window.location) === null || _a === void 0 ? void 0 : _a.host) || 'IDENTIFIER_NOT_PROVIDED';
        this.globalConfig.submissionEndpoint = config.submissionEndpoint;
        this.globalConfig.stageTwoEndpoint = config.stageTwoEndpoint;
        __classPrivateFieldGet(this, _App_instances, "m", _App_getUser).call(this).then((user) => {
            this.globalConfig.user.ipAddress = user.ip;
            this.globalConfig.user.userAgent = user.userAgent;
        });
    }
    addForm(htmlElementId, config) {
        if (!config.hubScrapFormId) {
            console.log('You must specify a formId parameter for each form, this parameter must match the id of a published hubscrap form');
            return false;
        }
        const elements = document.getElementsByClassName(htmlElementId);
        if (elements) {
            let elementsArray = Array.from(elements);
            elementsArray.forEach((element, elementIndex) => {
                if (!element.hasAttribute('sl-form-bound')) {
                    const formId = `sl_form_${elementIndex}_${Date.now()}`;
                    new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                        element.innerHTML = this.buildFormHtml(formId, config);
                        resolve(true);
                    })).finally(() => this.bindEventListener(element, formId, config));
                }
                console.log(config);
            });
        }
    }
    bindEventListener(element, id, formConfig) {
        element.firstChild.addEventListener('submit', (e) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            e.preventDefault();
            const formEntries = new FormData(e.target).entries();
            const fields = Array.from(formEntries, ([x, y]) => ({ [x]: y }));
            // @ts-ignore
            const formData = Object.assign(...fields);
            let postData = {
                category: formConfig.formType,
                submission_meta: {
                    submission_source_type: 'COMPANY_FORM',
                    submission_source_ident: formConfig.submissionSourceIdentifier || this.globalConfig.submissionSourceIdentifier || null,
                    submission_source_domain: window.location.hostname,
                    submission_source_url: window.location.href,
                    submission_source_form: formConfig.hubScrapFormId,
                },
                tracking: {
                    form_type: formConfig.formType || FormType.SCRAP_CAR,
                    form_layout: formConfig.formLayout || FormLayout.VERTICAL,
                    ip_address: this.globalConfig.user.ipAddress,
                    user_agent: this.globalConfig.user.userAgent
                },
                contacts: {},
                assets: [],
            };
            if (formData.vrm !== undefined) {
                postData.assets = [
                    {
                        type: 'VEHICLE',
                        type_meta: {
                            vrm: formData.vrm
                        },
                        additional_meta: {}
                    }
                ];
            }
            if (formData.postcode !== undefined) {
                postData.location = {
                    line_one: null,
                    line_two: null,
                    city: null,
                    region: null,
                    postcode: formData.postcode,
                    country: 'GB',
                    latitude: null,
                    longitude: null,
                };
            }
            if (formData.phone !== undefined) {
                postData.contacts.primary = {
                    title: null,
                    first_name: null,
                    last_name: null,
                    full_name: null,
                    phone: {
                        primary: {
                            country_code: '44',
                            number: formData.phone,
                        }
                    },
                };
            }
            if (formData.email !== undefined) {
                if (postData.contacts.primary !== undefined) {
                    postData.contacts.primary.email = {
                        address: formData.email
                    };
                }
                else {
                    postData.contacts.primary = {
                        title: null,
                        first_name: null,
                        last_name: null,
                        full_name: null,
                        email: {
                            address: formData.email
                        }
                    };
                }
            }
            if (formData.waste !== undefined) {
                postData.scrap_metal_waste_type = formData.waste;
            }
            const environment = __classPrivateFieldGet(this, _App_instances, "m", _App_getEnvironment).call(this);
            let endpoint = this.globalConfig.submissionEndpoint;
            endpoint += `/hubscrap/leads`;
            this.resetErrorMessages(element);
            this.setButtonProcessingText(element);
            const request = yield fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });
            const response = yield request.json().finally(() => this.resetButtonProcessingText(element));
            if (response.code === 422) {
                const errors = response.data.errors;
                const errorFieldMapping = {
                    'contacts.primary.email.address': 'email',
                    'location.postcode': 'postcode',
                    'contacts.primary.phone.primary.number': 'phone',
                    'assets.0.type_meta.vrm': 'vrm'
                };
                for (const key in errors) {
                    if (errorFieldMapping[key]) {
                        this.setErrorMessage(element, errorFieldMapping[key], errors[key][0]);
                    }
                }
            }
            if ([200, 201].includes(response.code)) {
                const responseData = (_a = response.data) === null || _a === void 0 ? void 0 : _a.records[0];
                const leadReference = responseData.new_lead_reference;
                if (leadReference) {
                    console.log(`${this.globalConfig.stageTwoEndpoint}/${formConfig.hubScrapFormId}/${leadReference}/router`);
                    window.location.href = `${this.globalConfig.stageTwoEndpoint}/${formConfig.hubScrapFormId}/${leadReference}/router`;
                }
            }
        }));
    }
    setErrorMessage(element, fieldKey, message) {
        const errorElement = element.querySelector(`.${fieldKey}-field-error`);
        if (errorElement) {
            errorElement.textContent = message;
            errorElement.style.display = 'block';
        }
    }
    resetErrorMessages(element) {
        const errorElements = element.querySelectorAll(`.field-error`);
        errorElements.forEach((node) => {
            node.textContent = '';
            node.style.display = 'none';
        });
    }
    setButtonProcessingText(element) {
        this.setSubmitButtonText(element, 'Processing...');
    }
    resetButtonProcessingText(element) {
        this.setSubmitButtonText(element, 'Get Quote');
    }
    setSubmitButtonText(element, text) {
        const childElements = element.getElementsByTagName('button');
        if (childElements.length > 0) {
            childElements[0].innerText = text;
        }
    }
    buildFormHtml(id, config) {
        const environment = __classPrivateFieldGet(this, _App_instances, "m", _App_getEnvironment).call(this);
        const fields = formFields[config.formType];
        let html = `<form id="${id}" class="scrap-local-form">`;
        html += `<div class="form-fields ${config.formLayout === FormLayout.HORIZONTAL ? 'horizontal-layout' : 'vertical-layout'}">`;
        if (fields) {
            for (const fieldKey in fields) {
                const field = fields[fieldKey];
                const disabledFields = config.disabledFields || [];
                if (!disabledFields.includes(fieldKey)) {
                    html += this.replaceBulk(field, {
                        '{waste_icon}': `${environment.endpoint}/${environment.fieldIcons.waste}`,
                        '{waste_icon_width}': '31',
                        '{waste_icon_height}': '32',
                        '{vrm_icon}': `${environment.endpoint}/${environment.fieldIcons.vrm}`,
                        '{vrm_icon_width}': '48',
                        '{vrm_icon_height}': '48',
                        '{postcode_icon}': `${environment.endpoint}/${environment.fieldIcons.postcode}`,
                        '{postcode_icon_width}': '48',
                        '{postcode_icon_height}': '48',
                        '{phone_icon}': `${environment.endpoint}/${environment.fieldIcons.phone}`,
                        '{phone_icon_width}': '29',
                        '{phone_icon_height}': '29',
                        '{email_icon}': `${environment.endpoint}/${environment.fieldIcons.email}`,
                        '{email_icon_width}': '31',
                        '{email_icon_height}': '24'
                    });
                }
            }
        }
        html += `</div>`;
        html += `<div class="form-controls ${config.formLayout === FormLayout.HORIZONTAL ? 'horizontal-layout' : 'vertical-layout'}">`;
        html += '<button type="submit" role="button">Get Quote</button>';
        html += `</div>`;
        html += '</form>';
        return html;
    }
    replaceBulk(str, obj) {
        let findArray = Object.keys(obj);
        let replaceArray = Object.values(obj);
        let i, regex = [], map = {};
        for (i = 0; i < findArray.length; i++) {
            regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, '\\$1'));
            map[findArray[i]] = replaceArray[i];
        }
        regex = regex.join('|');
        str = str.replace(new RegExp(regex, 'g'), function (matched) {
            return map[matched];
        });
        return str;
    }
}
_App_instances = new WeakSet(), _App_getEnvironment = function _App_getEnvironment() {
    let environment = {
        fieldIcons: {
            'waste': 'assets/images/icons/waste.png',
            'vrm': 'assets/images/icons/vrm.png',
            'postcode': 'assets/images/icons/postcode.png',
            'phone': 'assets/images/icons/phone.png',
            'email': 'assets/images/icons/email.png',
        },
        formTypePaths: {
            [FormType.SCRAP_CAR]: 'scrap-car',
            [FormType.SALVAGE]: 'salvage',
            [FormType.SCRAP_METAL]: 'scrap-metal',
            [FormType.AUTO_PARTS]: 'auto-parts',
        },
        endpoint: '',
        formSubmissionEndpoint: '',
        formApplicationEndpoint: '',
    };
    switch (this.globalConfig.environment) {
        case Environment.LOCAL:
            environment.endpoint = process.env.VUE_APP_ASSETS_URL;
            environment.formSubmissionEndpoint = process.env.VUE_APP_SUBMISSION_ENDPOINT;
            environment.formApplicationEndpoint = process.env.VUE_APP_FORMS_APP_URL;
            break;
        case Environment.STAGING:
            environment.endpoint = process.env.VUE_APP_ASSETS_URL;
            environment.formSubmissionEndpoint = process.env.VUE_APP_SUBMISSION_ENDPOINT;
            environment.formApplicationEndpoint = process.env.VUE_APP_FORMS_APP_URL;
            break;
        case Environment.PRODUCTION:
            environment.endpoint = process.env.VUE_APP_ASSETS_URL;
            environment.formSubmissionEndpoint = process.env.VUE_APP_SUBMISSION_ENDPOINT;
            environment.formApplicationEndpoint = process.env.VUE_APP_FORMS_APP_URL;
            break;
    }
    return environment;
}, _App_getUser = function _App_getUser() {
    const app = this;
    return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
        yield fetch('https://www.cloudflare.com/cdn-cgi/trace?format=json', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
            .then((response) => response.text())
            .then((response) => {
            const [, ip] = response.match(new RegExp("ip=" + "(.*)" + "\n"));
            const [, userAgent] = response.match(new RegExp("uag=" + "(.*)" + "\n"));
            resolve({ ip, userAgent });
        }).catch(() => resolve(true));
    }));
};
App.version = '2.0.0';
